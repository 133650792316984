(function ($) {
  Drupal.behaviors.contentBlockSplitWidthV1 = {
    attach: function (context) {
      var $modules = $('.js-content-block-split-width--v1', context);

      $modules.each(function () {
        var $module = $(this);

        $module.off('video.playing').on('video.playing', function (event) {
          $module.addClass('video-active');
        });

        $('.js-video-block', $module).on('video.close', function (event) {
          $module.removeClass('video-active');
        });
      });
    }
  };
})(jQuery);
